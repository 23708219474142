import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import StationsList from "../components/stationslist"

export default ({ data }) => {
  // Client-side Runtime Data Fetching
  const [stationDetails, setStationDetails] = useState([])
  useEffect(() => {
    fetch(
      `https://webapi.radioedit.iheart.com/graphql?query=query%20%7bleads(query:%20%7blimit:%201000,subscription:%20%7btags:%20%5b%22collections/web-originals%22,%20%22facets/originals-popular%22%5d%7d%7d)%20%7bcatalog%20%7bid%7dimg_uri%20title%20subtitle%20background_color%20link%20%7burls%7bdevice%20web%7d%7d%7d%7d`
    )
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        setStationDetails(resultData.data.leads)
      }) // set data for the genre
  }, [])
  return (
    <React.Fragment>
      <Layout>
        <SEO ogurl="/" title="iHeart Originals" />
        <h1>
          Original <span>Live</span> Radio Stations
        </h1>
        <StationsList stationDetails={stationDetails} />
      </Layout>
      <Helmet>
        <title></title>
      </Helmet>
    </React.Fragment>
  )
}
